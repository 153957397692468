import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import './Header.js';
import './'
import Header from './Header.js';

function Home() {
  return (
    <div className="menu-container">
      <Link className="menu-item" to="/account-info">Account Info</Link>
      <Link className="menu-item" to="#">Placeholder 1</Link>
      <Link className="menu-item" to="#">Placeholder 2</Link>
    </div>
  );
}

export default Home;