import React, { useState, useEffect, useMemo } from 'react';
import Header from './Header';
import HomePage from './Home';
import LoginPage from './login';
import axios from 'axios';


function Login() {
    const [url, setURL] = useState('');

    const loginButton = async () => {
        try {
            const response = await axios.get('/api/login');
            const fetchedUrl = response.data.redirect_url;
            console.log(response.data.redirect_url)
            if (fetchedUrl) {
            window.location.replace(fetchedUrl); // Redirect to the fetched URL
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (

        <div style={{ padding: '20px' }}>
            <div className="overall-pl" style={{ marginBottom: '20px' }}>
                <button onClick={loginButton}>Schwab Authentication</button>
            </div>
        </div>
    );
}
export default Login;