import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';  // Import Routes instead of Switch
import Header from './Header';
import HomePage from './Home';
import AccountInfoPage from './AccountInfo';
import LoginPage from './login';

function App() {
  return (
    <Router>
      <Header />
      <Routes>  {/* Replace Switch with Routes */}
        <Route path="/" element={<HomePage />} />  {/* Use element instead of component */}
        <Route path="/account-info" element={<AccountInfoPage />} />
        <Route path="/login" element={<LoginPage />} />
        {/* Add more routes here */}
      </Routes>
    </Router>
  );
}

export default App;
