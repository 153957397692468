import React, { useState, useEffect, useMemo } from 'react';
import { MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import './AccountInfo.css';  // Import the CSS file

function AccountInfo() {
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState(null);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'date',
        header: 'Date',
      },
      {
        accessorKey: 'action',
        header: 'Action',
      },
      {
        accessorKey: 'symbol',
        header: 'Symbol',
      },
      {
        accessorKey: 'description',
        header: 'Description',
      },
      {
        accessorKey: 'quantity',
        header: 'Quantity',
      },
      {
        accessorKey: 'price',
        header: 'Price',
      },
      {
        accessorKey: 'amount',
        header: 'Amount',
      },
    ],
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/account-info/');
        console.log(response.data.details); // Log the table data for debugging
        setData(response.data.details);
        setSummary(response.data.summary || {});
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  if (!summary) {
    return <div>Loading...</div>;
  }

  const getColorClass = (value) => {
    return value < 0 ? 'negative' : 'positive';
  };

  return (
    <div style={{ padding: '20px' }}>
      {/* Overall Profit/Loss Section */}
      <div className="overall-pl" style={{ marginBottom: '20px' }}>
        <p>Overall P/L</p>
        <span className={getColorClass(summary.totalPl)}>{`$${summary.totalPl || 0}`}</span>
      </div>

      {/* Grouped Summary Section */}
      <div className="summary-group">
        <div className="summary-group-title">Premium Summary</div>
        <div className="summary">
          <div className="summary-item">
            <p>Sell to Open:</p>
            <span className="positive">{`$${summary.totalPremiumCollected || 0}`}</span>
          </div>
          <div className="summary-item">
            <p>Buy to Close:</p>
            <span className="negative">{`$${Math.abs(summary.totalPaidToClose) || 0}`}</span>
          </div>
        </div>
        
        <div className="summary-group-title">Other Summary</div>
        <div className="summary">
          <div className="summary-item">
            <p>Fees and Commissions:</p>
            <span className="negative">{`$${summary.totalFeesComm || 0}`}</span>
          </div>
          <div className="summary-item">
            <p>Sell to Open:</p>
            <span>{summary.totalSellToOpen || 0}</span>
          </div>
          <div className="summary-item">
            <p>Buy to Close:</p>
            <span>{summary.totalBuyToClose || 0}</span>
          </div>
          <div className="summary-item">
            <p>Expired Contracts:</p>
            <span>{summary.totalExpired || 0}</span>
          </div>
          <div className="summary-item">
            <p>Assigned Contracts:</p>
            <span>{summary.totalAssigned || 0}</span>
          </div>
          <div className="summary-item">
            <p>Open Contracts:</p>
            <span>{summary.totalOpenContracts || 0}</span>
          </div>
          <div className="summary-item">
            <p>Total Contracts:</p>
            <span>{summary.totalContracts || 0}</span>
          </div>
        </div>
      </div>

      <h3 style={{ textAlign: 'center' }}>Detailed Transaction Information by Date</h3>
      <MaterialReactTable columns={columns} data={data} />
    </div>
  );
}

export default AccountInfo;
