import React from 'react';
import Logo from './Logo';  // Import the Logo component

const Header = () => {
  return (
    <header style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
      <Logo />
      {/* Add more header content like navigation links here */}
    </header>
  );
};

export default Header;
