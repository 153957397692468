// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

.menu-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.menu-item {
    background-color: #4CAF50;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 10px 0;
    border-radius: 5px;
    width: 80%;
    max-width: 300px;
    font-size: 18px;
    transition: background-color 0.3s ease;
}

.menu-item:hover {
    background-color: #45a049;
}

@media (max-width: 600px) {
    .menu-item {
        width: 100%;
        font-size: 16px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Home.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,qBAAqB;IACrB,cAAc;IACd,kBAAkB;IAClB,UAAU;IACV,gBAAgB;IAChB,eAAe;IACf,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI;QACI,WAAW;QACX,eAAe;IACnB;AACJ","sourcesContent":["body {\n    margin: 0;\n    padding: 0;\n    font-family: Arial, sans-serif;\n}\n\n.menu-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px;\n}\n\n.menu-item {\n    background-color: #4CAF50;\n    color: white;\n    padding: 15px 25px;\n    text-align: center;\n    text-decoration: none;\n    display: inline-block;\n    margin: 10px 0;\n    border-radius: 5px;\n    width: 80%;\n    max-width: 300px;\n    font-size: 18px;\n    transition: background-color 0.3s ease;\n}\n\n.menu-item:hover {\n    background-color: #45a049;\n}\n\n@media (max-width: 600px) {\n    .menu-item {\n        width: 100%;\n        font-size: 16px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
