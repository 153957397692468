import React from 'react';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import logo from './momo-logo.png';
import './Logo.css';

const Logo = () => {
  return (
    <Link to="/">  {/* Wrap the logo with Link and set the destination to the home page */}
      <img src={logo} alt="App Logo" className='logo'/>
    </Link>
  );
};

export default Logo;
